<template>
	<div class="allbox">
		<div style="background: #fff;padding: 15px;">
			<el-tabs v-model="currentTab" @tab-click="filterFun">
				<el-tab-pane v-for="item in tabList" :key="item.id" :label="item.value" :name="item.id+''">
				</el-tab-pane>
			</el-tabs>

			<div>
				<div class="filter-container">
					<div class="filter-item">
						<label class="label">关键字: </label>
						<el-input v-model="searchKey" :placeholder="currentTab==0?'订单编号':'优惠券名称'" style="width: 200px;">
						</el-input>
					</div>

					<div class="filter-item">
						<label class="label">核销时间: </label>
						<el-date-picker type="datetime" placeholder="开始时间" v-model="starTime" style="width: 250px;"
							value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
						<el-date-picker type="datetime" placeholder="结束时间" v-model="endTime" style="width: 250px;"
							value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</div>

					<div class="filter-item">
						<label class="label">核销门店: </label>
						<el-select v-model="doorState" placeholder="请选择" filterable style="width: 160px;">
							<el-option :value="null" label="全部"></el-option>
							<el-option v-for="(item,index) in doorList" :key="item.Id" :label="item.ShopName"
								:value="item.Id">
							</el-option>
						</el-select>
					</div>

					<div class="filter-item">
						<label class="label">核销员工: </label>
						<el-select v-model="staffState" placeholder="请选择" filterable style="width: 160px;">
							<el-option :value="null" label="全部"></el-option>
							<el-option v-for="(item,index) in staffList" :key="item.Id" :label="item.EmployeeName"
								:value="item.Id">
							</el-option>
						</el-select>
					</div>

					<div class="filter-item">
						<el-button type="primary" style="width:90px;" @click="handleSearch">查询</el-button>

						<button-permissions :datas="'downloadPickUpOffBtn'" v-show="currentTab==0">
							<el-button style="margin-left:15px;width:90px;" @click="handleExport">导出</el-button>
						</button-permissions>

						<button-permissions :datas="'downloadCouponOffBtn'" v-show="currentTab==1">
							<el-button style="margin-left:15px;width:90px;" @click="handleExport">导出</el-button>
						</button-permissions>

					</div>
				</div>

				<div class="table-container">
					<!-- table1 -->
					<el-table :data="pickUpList" v-show="currentTab==0" style="width: 100%;" v-loading="loading">
						<el-table-column label="订单编号" :key="0" width="250px">
							<template slot-scope="scope">
								<span style="color:#409EFF;cursor:pointer"
									@click="openNewWindow(scope.row.OrderNo)">{{scope.row.OrderNo}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="PickUpShopName" label="核销门店" width="220px" :key="1"></el-table-column>
						<el-table-column prop="VerificationTime" label="核销时间" width="250px" :key="2"></el-table-column>
						<el-table-column label="核销员工" :key="3">
							<template slot-scope="scope">
								<span>{{scope.row.EmployeeName}}</span>
								<span v-if="scope.row.EmployeePhone">({{scope.row.EmployeePhone}})</span>
							</template>
						</el-table-column>
						<el-table-column prop="SelectShopName" label="提货门店" :key="4"></el-table-column>
						<el-table-column label="提货人" :key="5">
							<template slot-scope="scope">
								<span>{{scope.row.ReceiveName}}</span>
								<span v-if="scope.row.ReceivePhone">({{scope.row.ReceivePhone}})</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" :key="11">
							<template slot-scope="scope">
								<el-button type="text" @click="pickUpDetail(scope.row)">提货明细</el-button>
							</template>
						</el-table-column>
					</el-table>

					<!-- table2 -->
					<el-table :data="couponList" v-show="currentTab==1" style="width: 100%;" v-loading="loading">
						<el-table-column label="优惠券名称" prop="CouponName" :key="6"></el-table-column>
						<el-table-column prop="PickUpShopName" label="核销门店" :key="7"></el-table-column>
						<el-table-column prop="VerificationTime" label="核销时间" :key="8"></el-table-column>
						<el-table-column label="核销员工" :key="9">
							<template slot-scope="scope">
								<span>{{scope.row.EmployeeName}}</span>
								<span v-if="scope.row.EmployeePhone">({{scope.row.EmployeePhone}})</span>
							</template>
						</el-table-column>
						<el-table-column label="顾客" :key="10">
							<template slot-scope="scope">
								<span style="color:#409EFF;cursor:pointer;display:block;"
									@click="openNewWindowCus(scope.row.MemberId)">{{scope.row.MemberWxNickname}}</span>
								<span>{{scope.row.MemberPhone}}</span>
							</template>
						</el-table-column>
					</el-table>

					<div style="text-align:right;">
						<el-pagination v-if="page.total" style="margin-top:20px;" @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="page.current"
							:page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
							layout="total, sizes, prev, pager, next, jumper" :total="page.total">
						</el-pagination>
					</div>
				</div>

			</div>

			<el-dialog title="提货明细" :visible.sync="pickUpDetaileVisible" width="800px" class="pick-up-detail">
				<div>
					<span>订单编号：</span>
					<el-button type="text" @click="openNewWindow(pickUpOrderNo)">{{pickUpOrderNo}}</el-button>
				</div>
				<el-table v-loading='detailLoading' :data="pickUpDetailList" default-expand-all
					:row-class-name="setClassName" max-height="500">
					<el-table-column type="expand">
						<template slot-scope="scope">
							<div style="overflow:hidden;" v-if="scope.row.IsMix">
								<div class="reply-expand" :key="index" v-for="(item ,index) in scope.row.MixList">
									<div class="product-name">
										<div class="name">{{item.ProductName}}</div>
										<div style="color:#909399;">{{item.ProductNo}}</div>
									</div>
									<div class="product-spec">
										<template v-if="item.SpecValue || item.SpecValue2">
											<span v-if="item.SpecValue">{{item.SpecValue}}</span>
											<span v-if="item.SpecValue2">;{{item.SpecValue2}}</span>
										</template>
										<span v-else>默认规格</span>
										<div style="color:#909399;" v-if="item.Barcode">{{item.Barcode}}</div>
									</div>
									<div class="product-msg">
										<div>组合内数量：{{item.ProductCount}}</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column label="商品" width="300">
						<template slot-scope="scope">
							<div class="product-name">
								<div class="name">
									<span style="color:#F56C5C" v-if="scope.row.IsGift">[赠品]</span>
									<span style="color:#F56C5C" v-if="scope.row.IsMix">[组合]</span>
									<span>{{scope.row.ProductName}}</span>
								</div>
								<div style="color:#909399;">{{scope.row.ProductNo}}</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column label="规格" width="200">
						<template slot-scope="scope">
							<template v-if="scope.row.SpecValue || scope.row.SpecValue2">
								<span v-if="scope.row.SpecValue">{{scope.row.SpecValue}}</span>
								<span v-if="scope.row.SpecValue2">;{{scope.row.SpecValue2}}</span>
							</template>
							<span v-else>默认规格</span>
							<div style="color:#909399;" v-if="scope.row.Barcode">{{scope.row.Barcode}}</div>
						</template>
					</el-table-column>

					<el-table-column label="提货数量" prop="SendCount"></el-table-column>

				</el-table>
			</el-dialog>

		</div>
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import config from '@/config/index'
	import apiList from '@/api/other'
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				exportUrl: config.EXPORT_URL,

				searchKey: '',
				starTime: '',
				endTime: '',
				currentTab: 0,
				tabList: [{
						id: 0,
						value: '自提单核销'
					},
					{
						id: 1,
						value: '优惠券核销'
					}
				],
				doorState: null,
				doorList: [],
				staffState: null,
				staffList: [],

				loading: false,
				pickUpList: [],
				couponList: [],
				page: {
					size: 20,
					current: 1,
					total: 0
				},

				detailLoading: false,
				pickUpOrderNo: '',
				pickUpDetaileVisible: false,
				pickUpDetailList: []
			};
		},
		//初始化数据
		beforeMount() {
			this.getData()
			this.getStateList()
		},
		methods: {
			//获取列表
			async getData() {
				this.loading = true
				try {
					let data = {
						Keywords: this.searchKey,
						StartTime: this.starTime,
						EndTime: this.endTime,
						PickUpShopId: this.doorState,
						EmployeeId: this.staffState,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
					}
					if (this.currentTab == 0) {
						let result = await apiList.verificationOrderIndex(data)
						this.pickUpList = result.Result.Results || []
						this.page.total = result.Result.Total
					} else {
						let result = await apiList.verificationCouponIndex(data)
						this.couponList = result.Result.Results || []
						this.page.total = result.Result.Total
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//顶部tab切换事件
			filterFun(tab, event) {
				this.page.current = 1
				this.searchKey = ''
				this.starTime = ''
				this.endTime = ''
				this.doorState = null
				this.staffState = null
				this.getData()
			},
			async getStateList(id) {
				try {
					let result = await apiList.verificationCouponInit()
					this.staffList = result.Result.EmployeeList
					this.doorList = result.Result.ShopList
				} catch (err) {} finally {}
			},
			handleSearch() {
				this.page.current = 1
				this.getData()
			},
			async handleExport() {
				this.loading = true
				try {
					let api = this.currentTab == 1 ? '/pc/verification-record/coupon-export' :
						'/pc/verification-record/order-export'
					let url = this.exportUrl + api + '?' +
						'&Keywords=' + this.searchKey +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&PickUpShopId=' + (this.doorState ? this.doorState : '') +
						'&EmployeeId=' + (this.staffState ? this.staffState : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			async pickUpDetail(val) {
				this.pickUpOrderNo = val.OrderNo
				this.pickUpDetaileVisible = true
				try {
					this.detailLoading = true
					let result = await apiList.verificationOrderDetail({
						OrderNo: this.pickUpOrderNo
					})
					this.pickUpDetailList = result.Result

				} catch (e) {

				} finally {
					this.detailLoading = false
				}
			},
			setClassName({
				row,
				index
			}) {
				let length = 0
				if (row && row.MixList) {
					length = row.MixList.length
				}
				if (!length) {
					return 'unexpand'
				} else {
					return ''
				}
			},
			openNewWindow(orderNo) {
				let url = '/order/orderDetailpage?Id=' + orderNo
				this.$common.openNewWindow(url)
			},
			openNewWindowCus(id) {
				let url = '/Customer/CustomerDetail?Id=' + id
				this.$common.openNewWindow(url)
			},
			handleSizeChange(val) {
				this.page.size = val;
				this.getData()
			},
			handleCurrentChange(val) {
				this.page.current = val;
				this.getData()
			},
		}
	}
</script>

<style lang="less" scoped>
	.pick-up-detail {
		.reply-expand {
			::v-deep .el-table th {
				padding: 0 !important;
			}

			width: 100%;
			background-color: #f4f5f7;
			display: flex;
			flex-direction: row;
			padding: 10px;
		}

		.product-name {
			width: 290px;
			display: flex;
			flex-direction: column;

			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;

			}
		}

		.product-msg {
			word-wrap: break-word;
			display: flex;
			padding: 0 9px;
			align-items: center;
		}

		.product-spec {
			word-wrap: break-word;
			width: 200px;
			padding: 0 9px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

	}

	::v-deep .unexpand .el-table__expand-column .cell {
		display: none;
	}

	::v-deep .unexpand .el-table__expanded-cell {
		display: none;
	}

	::v-deep .el-table__expanded-cell[class*=cell] {
		padding: 0 0 0 50px;
	}

	::v-deep .el-table__expanded-cell {
		padding-left: 50px;
	}
</style>
